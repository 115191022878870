<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("sp_act.title") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-3">
            <h6 class="ssp-24 ml mb-1">{{ $t("sp_act.title") }}</h6>
          </div>
          <div class="mx-3">
            <div class="row table-utilities mx-0">
              <div class="row ml-2 w-100">
                <div class="col-md-10 search-section">
                  <div class="input-group">
                    <div class="input-group-prepend bg-white">
                      <span class="input-group-text" id="basic-addon1">
                        <div class="font-12">
                          <i class="ri-search-line ri-lg ri-sub"></i>
                        </div>
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control search-input"
                      :placeholder="$t('sp_act.search')"
                      @input="setPage"
                      v-model="tabKegiatan.filterInput"
                    />
                  </div>
                </div>
                <div class="col-md-2 pl-0">
                  <button
                    class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
                    @click="handleExport"
                    :disabled="isLoading"
                  >
                    <div class="font-12">
                      <div
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm text-success mr-1"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
                    </div>
                    {{ $t("global.export") }}
                  </button>
                </div>
              </div>
              <div class="row w-100 ml-2 mt-2">
                <div class="col-md-2 pl-0">
                  <b-button
                    class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
                    id="popover-button-variant"
                    href="#"
                    tabindex="0"
                  >
                    <div class="font-12">
                      <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                    </div>
                    {{ $t("global.filter") }}
                  </b-button>
                </div>
                <b-popover
                  placement="right"
                  target="popover-button-variant"
                  variant="danger"
                  triggers="focus"
                >
                  <b-tabs content-class="mt-2">
                    <b-tab title="Tanggal" active>
                      <div class="form-check multiple-row">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="radio-today"
                          :value="filterDateTypes[0]"
                          @change="resetFilter1"
                          checked
                          v-model="filterDateType"
                        />
                        <label class="form-check-label" for="radio-today">
                          <div class="col">
                            <div class="row ssp-14">Today</div>
                            <div class="row">{{ today | formatDate2 }}</div>
                          </div>
                        </label>
                      </div>
                      <div class="form-check multiple-row">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="radio-yesterday"
                          :value="filterDateTypes[1]"
                          @change="resetFilter1"
                          v-model="filterDateType"
                        />
                        <label class="form-check-label" for="radio-yesterday">
                          <div class="col">
                            <div class="row ssp-14">Yesterday</div>
                            <div class="row">{{ yesterday | formatDate2 }}</div>
                          </div>
                        </label>
                      </div>
                      <div class="form-check multiple-row">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="radio-week"
                          :value="filterDateTypes[2]"
                          @change="setFilterSelectedWeek"
                          v-model="filterDateType"
                        />
                        <label class="form-check-label" for="radio-week">
                          <div class="col">
                            <div class="row ssp-14">This week</div>
                            <div class="row">
                              <span>{{
                                `${getDay(firstDayWeek)}&nbsp;-&nbsp;`
                              }}</span>
                              <span>{{ lastDayWeek | formatDate2 }}</span>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div class="form-check multiple-row">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="radio-month"
                          :value="filterDateTypes[3]"
                          @change="setFilterSelectedMonth"
                          v-model="filterDateType"
                        />
                        <label class="form-check-label" for="radio-month">
                          <div class="col">
                            <div class="row ssp-14">This month</div>
                            <div class="row">
                              <span>{{
                                `${getDay(firstDayMonth)}&nbsp;-&nbsp;`
                              }}</span>
                              <span>{{ lastDayMonth | formatDate2 }}</span>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div class="form-check mb-2">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="radio-custom"
                          :value="filterDateTypes[4]"
                          v-model="filterDateType"
                        />
                        <label
                          class="form-check-label ssp-14"
                          for="radio-custom"
                        >
                          Custom Range
                        </label>
                      </div>
                      <b-row
                        v-if="filterDateType == filterDateTypes[4]"
                        class="mb-2"
                      >
                        <b-col cols="6" class="pr-1">
                          <span>FROM</span>
                          <input
                            type="date"
                            class="form-control float-right"
                            v-model="filterSelected1"
                          />
                        </b-col>
                        <b-col cols="6" class="pl-1">
                          <span>TO</span>
                          <input
                            type="date"
                            class="form-control"
                            v-model="filterSelected2"
                          />
                        </b-col>
                      </b-row>
                      <button
                        @click="resetFilter"
                        type="string"
                        class="btn btn-success w-100 ssp-14"
                        :class="{ disabled: filterDateType === null }"
                      >
                        {{ $t("filter.reset") }}
                      </button>
                    </b-tab>
                    <b-tab title="Nama SP">
                      <div class="input-group">
                        <div class="input-group-prepend bg-white">
                          <span class="input-group-text" id="basic-addon1">
                            <div class="font-12">
                              <i class="ri-search-line ri-lg ri-sub"></i>
                            </div>
                          </span>
                        </div>
                        <input
                          type="text"
                          class="form-control search-input"
                          v-model="searchSP"
                        />
                      </div>
                      <hr class="m-2" />
                      <div class="filter-overflow-srch">
                        <div
                          class="form-checkbox"
                          v-for="sp in dataSP"
                          :key="sp.id"
                        >
                          <input
                            class="form-checkbox-item"
                            type="checkbox"
                            name="kegiatan-label"
                            :value="sp.id"
                            :id="sp.id"
                            v-model="filterSP"
                          />
                          <label
                            class="form-checkbox-label ssp-14-400"
                            :for="sp.id"
                          >
                            {{ sp.name }}
                          </label>
                        </div>
                      </div>
                      <button
                        @click="resetFilter"
                        type="string"
                        class="btn btn-success w-100 mt-2 ssp-14"
                        :class="{ disabled: filterSP == '' }"
                      >
                        {{ $t("filter.reset") }}
                      </button>
                    </b-tab>
                    <b-tab title="Region">
                      <div class="filter-overflow-srch">
                        <div
                          class="form-checkbox"
                          v-for="rg in dataRegion"
                          :key="rg.id"
                        >
                          <input
                            class="form-checkbox-item"
                            type="checkbox"
                            name="kegiatan-label"
                            :value="parseInt(rg.id)"
                            :id="rg.id"
                            v-model="filterRegion"
                          />
                          <label
                            class="form-checkbox-label ssp-14-400"
                            :for="rg.id"
                          >
                            {{ rg.region }}
                          </label>
                        </div>
                      </div>
                      <button
                        @click="resetFilter"
                        type="string"
                        class="btn btn-success w-100 mt-2 ssp-14"
                        :class="{ disabled: filterRegion.length === 0 }"
                      >
                        {{ $t("filter.reset") }}
                      </button>
                    </b-tab>
                    <b-tab title="Kegiatan">
                      <div class="filter-overflow">
                        <div
                          class="form-checkbox"
                          v-for="kegiatan in kegiatanSP"
                          :key="kegiatan.id"
                        >
                          <input
                            :id="kegiatan.value"
                            class="form-radio-item"
                            type="radio"
                            name="kegiatan-label"
                            :value="kegiatan.value"
                            v-model="filterKegiatan"
                          />
                          <label
                            class="form-radio-label ssp-14-400"
                            :for="kegiatan.value"
                          >
                            {{ kegiatan.label }}
                          </label>
                        </div>
                      </div>
                      <button
                        @click="resetFilter"
                        type="string"
                        class="btn btn-success w-100 mt-2 ssp-14"
                        :class="{ disabled: filterKegiatan === null }"
                      >
                        {{ $t("filter.reset") }}
                      </button>
                    </b-tab>
                  </b-tabs>
                </b-popover>
              </div>
            </div>
            <div class="table-borderless">
              <b-table-simple hover responsive>
                <b-thead class="bv-head">
                  <b-tr>
                    <!-- <b-th><input type="checkbox" @click="selectAll" v-model="allSelected"></b-th> -->
                    <b-th>#</b-th>
                    <b-th v-for="hd in $t('sp_act.headers')" :key="hd.id">
                      {{ hd }}
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody v-if="loading">
                  <table-spinner cs="6" />
                </b-tbody>
                <b-tbody v-else-if="this.dataKegiatan.length !== 0">
                  <!-- <b-tbody v-if="this.dataLikuidasi.length !== 0"> -->
                  <b-tr
                    class="ssp-16-400"
                    v-for="(data, index) in dataKegiatan"
                    :key="data.id"
                  >
                    <b-td>
                      {{
                        ++index +
                          (tabKegiatan.currPage - 1) * tabKegiatan.perPage
                      }}
                    </b-td>
                    <b-td>{{ data.sales_promotor.name }}</b-td>
                    <b-th>{{ data.tanggal_kegiatan | formatDate2 }}</b-th>
                    <b-td>{{ data.lokasi }}</b-td>
                    <b-td>
                      <span
                        v-if="data.kegiatan === 'DEMOPLOT'"
                        class="badge badge-brown"
                        >{{ convertKegiatan(data.kegiatan) }}</span
                      >
                      <span
                        v-if="data.kegiatan === 'FFD'"
                        class="badge badge-orange"
                        >{{ convertKegiatan(data.kegiatan) }}</span
                      >
                      <span
                        v-if="data.kegiatan === 'SFM'"
                        class="badge badge-red"
                        >{{ convertKegiatan(data.kegiatan) }}</span
                      >
                      <span
                        v-if="data.kegiatan === 'SPRAY'"
                        class="badge badge-purple-2"
                        >{{ convertKegiatan(data.kegiatan) }}</span
                      >
                      <span
                        v-if="data.kegiatan === 'ODP'"
                        class="badge badge-yellow"
                        >{{ convertKegiatan(data.kegiatan) }}</span
                      >
                      <span
                        v-if="data.kegiatan === 'BFM'"
                        class="badge badge-gray"
                        >{{ convertKegiatan(data.kegiatan) }}</span
                      >
                      <span
                        v-if="data.kegiatan === 'KFM'"
                        class="badge badge-green"
                        >{{ convertKegiatan(data.kegiatan) }}</span
                      >
                      <span
                        v-if="data.kegiatan === 'SCP'"
                        class="badge badge-white"
                        >{{ convertKegiatan(data.kegiatan) }}</span
                      >
                      <span
                        v-if="data.kegiatan === 'SCK'"
                        class="badge badge-purple"
                        >{{ convertKegiatan(data.kegiatan) }}</span
                      >
                    </b-td>
                    <b-td class="action-column">
                      <div class="action-wrapper font-12">
                        <i
                          class="ri-eye-fill ri-lg ri-mid action-btn"
                          @click="onKegDetail(data)"
                        ></i>
                      </div>
                    </b-td>
                  </b-tr>
                </b-tbody>
                <b-tbody class="text-center text-italic" v-else>
                  <b-tr>
                    <b-td colspan="10">{{ $t("global.empty") }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>

              <div class="row align-items-center table-footer">
                <div class="col-sm-6 ssp-14-700">
                  {{ $t("global.total_data") }} {{ this.tabKegiatan.totalData }}
                </div>
                <div class="col-sm-6">
                  <div class="row">
                    <div class="col-sm-7 align-self-center page-text">
                      {{ $t("global.page_on") }}
                    </div>
                    <div class="col float-right">
                      <div class="row">
                        <select
                          class="form-control pagination-pg"
                          v-model="tabKegiatan.currPage"
                        >
                          <option
                            v-for="index in tabKegiatan.totalPages"
                            :key="index.id"
                            :value="index"
                          >
                            {{ index++ }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col page-arrow ssp-14-700 ml-2">
                      <div class="row float-right">
                        <div
                          @click="redcPage"
                          class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                        >
                          <div
                            class="font-12"
                            :class="{ orange: tabKegiatan.currPage > 1 }"
                          >
                            <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                          </div>
                        </div>
                        <div
                          @click="addPage"
                          class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                        >
                          <div
                            class="font-12"
                            :class="{
                              orange:
                                tabKegiatan.currPage < tabKegiatan.totalPages
                            }"
                          >
                            <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-detail" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">{{ modalTitle }}</h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>
      <template #default="{ hide }">
        <table class="tabel-detail">
          <tbody>
            <tr>
              <th>{{ $t("sp_act.label.sp") }}</th>
              <td>:</td>
              <td>
                <strong>{{ detailKegiatan.sales_promotor.name }}</strong>
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.act_type") }}</th>
              <td>:</td>
              <td>
                <strong>{{ convertKegiatan(detailKegiatan.kegiatan) }}</strong>
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.date") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.tanggal_kegiatan | formatDate2 }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.loc") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.lokasi }}</td>
            </tr>
          </tbody>
          <tbody v-if="detailKegiatan.kegiatan == 'DEMOPLOT'">
            <tr>
              <th>{{ $t("sp_act.label.farmer") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.petanis != null">
                {{ detailKegiatan.petanis.name }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.crop") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.tanamans != null">
                {{ detailKegiatan.tanamans.crop }}
              </td>
            </tr>
            <tr>
              <th>HST</th>
              <td>:</td>
              <td>{{ detailKegiatan.hst }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.opt") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.opt_sasaran }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.landarea") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.luas_demoplot }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.ap_date") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.tanggal_aplikasi | formatDate2 }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.ob_date") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.tanggal_pengamatan | formatDate2 }}</td>
            </tr>
            <tr>
              <th colspan="3">{{ $t("sp_act.label.ap_prod") }}</th>
            </tr>
            <tr
              v-for="(prod, index) in detailKegiatan.aplikasiKegiatan"
              :key="prod.id"
            >
              <td>- {{ $t("sp_act.label.prod") }} {{ ++index }}</td>
              <td>:</td>
              <td>
                {{ prod.produk ? prod.produk.brand : "-" }}
                ({{ prod.konsentrasi_rekomendasi }} ml/L, gr/L)
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.vol") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.volume }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.res") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kesimpulan }}</td>
            </tr>
          </tbody>
          <tbody v-if="detailKegiatan.kegiatan == 'SPRAY'">
            <tr>
              <th>{{ $t("sp_act.label.crop") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.tanamans != null">
                {{ detailKegiatan.tanamans.crop }}
              </td>
            </tr>
            <tr>
              <th>HST</th>
              <td>:</td>
              <td>{{ detailKegiatan.hst }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.opt") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.opt_sasaran }}</td>
            </tr>
            <!-- <tr>
              <th>Luas Lahan Spray</th>
              <td>:</td>
              <td>{{ detailKegiatan.opt_sasaran }}</td>
            </tr> -->
            <tr>
              <th>{{ $t("sp_act.label.ap_prod") }}</th>
            </tr>
            <tr
              v-for="(prod, index) in detailKegiatan.aplikasiKegiatan"
              :key="prod.id"
            >
              <td>- {{ $t("sp_act.label.prod") }} {{ ++index }}</td>
              <td>:</td>
              <td>
                {{ prod.produk ? prod.produk.brand : "-" }}
                ({{ prod.konsentrasi_rekomendasi }} ml/L, gr/L)
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.vol") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.volume }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.inv_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_undang }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pr_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_hadir }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.farmer_apl") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_aplikator }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.cons") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kekurangan }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pros") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kelebihan }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.sales_act") }}</th>
              <td>:</td>
              <td>
                <span v-if="detailKegiatan.isExist === true">&#9989;</span>
                <span v-else>&#10060;</span>
              </td>
            </tr>
          </tbody>
          <tbody v-if="detailKegiatan.kegiatan == 'FFD'">
            <tr>
              <th>{{ $t("sp_act.label.farmer") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.petanis != null">
                {{ detailKegiatan.petanis.name }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.crop") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.tanamans != null">
                {{ detailKegiatan.tanamans.crop }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.prodf") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.fokus_produk }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.inv_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_undang }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pr_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_hadir }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.cons") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kekurangan }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pros") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kelebihan }}</td>
            </tr>
          </tbody>
          <tbody v-if="detailKegiatan.kegiatan == 'ODP'">
            <tr>
              <th>{{ $t("sp_act.label.inv_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_undang }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pr_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_hadir }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.cons") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kekurangan }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.liq") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.produk_liquidasi }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pros") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kelebihan }}</td>
            </tr>
          </tbody>
          <tbody v-if="detailKegiatan.kegiatan == 'SFM'">
            <tr>
              <th>{{ $t("sp_act.label.crop") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.tanamans != null">
                {{ detailKegiatan.tanamans.crop }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.prodf") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.fokus_produk }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.inv_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_undang }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pr_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_hadir }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.cons") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kekurangan }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.liq") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.produk_liquidasi }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pros") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kelebihan }}</td>
            </tr>
          </tbody>
          <tbody v-if="detailKegiatan.kegiatan == 'BFM'">
            <tr>
              <th>{{ $t("sp_act.label.crop") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.tanamans != null">
                {{ detailKegiatan.tanamans.crop }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.prodf") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.fokus_produk }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.inv_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_undang }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pr_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_hadir }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.cons") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kekurangan }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.liq") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.produk_liquidasi }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pros") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kelebihan }}</td>
            </tr>
          </tbody>
          <tbody v-if="detailKegiatan.kegiatan == 'KFM'">
            <tr>
              <th>{{ $t("sp_act.label.crop") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.tanamans != null">
                {{ detailKegiatan.tanamans.crop }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.prodf") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.fokus_produk }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.inv_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_undang }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pr_farmer") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_petani_hadir }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.cons") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kekurangan }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.liq") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.produk_liquidasi }}</td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pros") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kelebihan }}</td>
            </tr>
          </tbody>
          <tbody
            v-if="
              (detailKegiatan.isExist === true &&
                detailKegiatan.kegiatan == 'SPRAY') ||
                (detailKegiatan.isExist === true &&
                  detailKegiatan.kegiatan == 'FFD') ||
                (detailKegiatan.isExist === true &&
                  detailKegiatan.kegiatan == 'ODP') ||
                (detailKegiatan.isExist === true &&
                  detailKegiatan.kegiatan == 'SFM') ||
                (detailKegiatan.isExist === true &&
                  detailKegiatan.kegiatan == 'BFM') ||
                (detailKegiatan.isExist === true &&
                  detailKegiatan.kegiatan == 'SFM')
            "
          >
            <tr v-if="detailKegiatan.retailers">
              <th>{{ $t("sp_act.label.ret") }}</th>
            </tr>
            <tr>
              <td v-if="detailKegiatan.retailers">
                <tr>
                  -
                  {{
                    $t("sp_act.label.ret_name")
                  }}
                </tr>
                <tr>
                  -
                  {{
                    $t("sp_act.label.ret_addr")
                  }}
                </tr>
                <tr v-if="detailKegiatan.retailers.alamat.length > 45">
                  &nbsp;
                </tr>
                <tr>
                  -
                  {{
                    $t("sp_act.label.ret_phone")
                  }}
                </tr>
              </td>

              <td v-if="detailKegiatan.retailers">
                <tr>
                  :
                </tr>
                <tr v-if="detailKegiatan.retailers.alamat.length > 45">
                  &nbsp;
                </tr>
                <tr>
                  :
                </tr>
                <tr>
                  :
                </tr>
              </td>

              <td v-if="detailKegiatan.retailers">
                <tr>
                  {{
                    `${detailKegiatan.retailers.name} (${detailKegiatan.retailers.retailer_type}) - ${detailKegiatan.retailers.owner}`
                  }}
                </tr>
                <tr>
                  {{
                    detailKegiatan.retailers.alamat
                      ? detailKegiatan.retailers.alamat
                      : "-"
                  }}
                </tr>
                <tr>
                  {{
                    detailKegiatan.retailers.mobile
                      ? detailKegiatan.retailers.mobile
                      : "-"
                  }}
                </tr>
              </td>
            </tr>
          </tbody>
          <tbody
            v-if="
              detailKegiatan.kegiatan == 'SPRAY' ||
                detailKegiatan.kegiatan == 'FFD' ||
                detailKegiatan.kegiatan == 'ODP' ||
                detailKegiatan.kegiatan == 'SFM' ||
                detailKegiatan.kegiatan == 'BFM' ||
                detailKegiatan.kegiatan == 'KFM'
            "
          >
            <tr>
              <th>
                {{ $t("sp_act.label.sales") }}
              </th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_hasil_penjualan | formatRp }}</td>
            </tr>
            <tr>
              <th>
                {{ $t("sp_act.label.farmer_buying") }}
              </th>
              <td>:</td>
              <td>{{ detailKegiatan.jml_beli_langsung }}</td>
            </tr>
            <tr>
              <th>
                {{ $t("sp_act.label.budget") }}
              </th>
              <td>:</td>
              <td>{{ detailKegiatan.budget | formatRp }}</td>
            </tr>
            <tr>
              <th>
                {{ $t("sp_act.label.reward") }}
              </th>
              <td>:</td>
              <td>{{ detailKegiatan.hadiah }}</td>
            </tr>
            <tr>
              <th colspan="3">
                {{ $t("sp_act.label.liq_product") }}
              </th>
            </tr>
            <tr
              v-for="(prod, index) in detailKegiatan.LiquidasiKegiatan"
              :key="prod.id"
            >
              <td>- {{ $t("sp_act.label.prod") }} {{ ++index }}</td>
              <td>:</td>
              <td>
                {{ `${prod.produk.brand} (${prod.jumlah_produk}) Pcs/Btl` }}
              </td>
            </tr>
            <tr></tr>
          </tbody>
          <tbody v-if="detailKegiatan.kegiatan == 'SCP'">
            <tr>
              <th>{{ $t("sp_act.label.farmer") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.petanis != null">
                {{ detailKegiatan.petanis.name }}
              </td>
            </tr>
            <tr>
              {{
                $t("sp_act.label.ret_addr")
              }}
              <td>:</td>
              <td v-if="detailKegiatan.petanis != null">
                {{ detailKegiatan.petanis.alamat }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.ret_phone") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.petanis != null">
                {{ detailKegiatan.petanis.hp }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.landarea") }}</th>
              <td>:</td>
              <td>
                {{ detailKegiatan.luas_demoplot }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.main_crop") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.petanis != null">
                <span v-if="detailKegiatan.petanis.ocrops != null">{{
                  detailKegiatan.petanis.ocrops.crop
                }}</span>
                <span v-else>
                  -
                </span>
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.other_crop") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.petanis != null">
                <span v-if="detailKegiatan.petanis.ocrops != null">{{
                  detailKegiatan.petanis.ucrops.crop
                }}</span>
                <span v-else>
                  -
                </span>
              </td>
            </tr>
            <tr>
              <th style="vertical-align: top;">
                {{ $t("sp_act.label.call_prp") }}
              </th>
              <td style="vertical-align: top;">:</td>
              <td>
                <tr v-for="keg in detailKegiatan.salesKegiatan" :key="keg.id">
                  {{
                    ` - ${keg.tujuan_sales_call_petani}`
                  }}
                </tr>
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.call_res") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.kesimpulan }}</td>
            </tr>
          </tbody>
          <tbody v-if="detailKegiatan.kegiatan == 'SCK'">
            <tr>
              <th>{{ $t("sp_act.label.ki_name") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.retailers != null">
                {{ detailKegiatan.retailers.name }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.ki_owner") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.retailers != null">
                {{ detailKegiatan.retailers.owner }}
              </td>
            </tr>
            <tr>
              <th style="vertical-align: top;">
                {{ $t("sp_act.label.ki_addr") }}
              </th>
              <td style="vertical-align: top;">:</td>
              <td v-if="detailKegiatan.retailers != null">
                {{ detailKegiatan.retailers.alamat }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.ret_phone") }}</th>
              <td>:</td>
              <td v-if="detailKegiatan.retailers != null">
                {{ detailKegiatan.retailers.mobile }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.ki_stat") }}</th>
              <td>:</td>
              <td>{{ detailKegiatan.level_toko }}</td>
            </tr>
            <tr>
              <th style="vertical-align: top;">
                {{ $t("sp_act.label.call_prp") }}
              </th>
              <td style="vertical-align: top;">:</td>
              <td>
                <tr v-for="keg in detailKegiatan.salesKegiatan" :key="keg.id">
                  {{
                    ` - ${keg.tujuan_sales_call_kios}`
                  }}
                </tr>
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pop") }}</th>
              <td>:</td>
              <td>
                <tr v-for="keg in detailKegiatan.salesKegiatan" :key="keg.id">
                  <span v-if="keg.po_produk !== 'null'">{{
                    keg.po_produk
                  }}</span>
                </tr>
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.sup_act") }}</th>
              <td>:</td>
              <td>
                <tr v-for="keg in detailKegiatan.salesKegiatan" :key="keg.id">
                  <span v-if="keg.support_kegiatan !== 'null'">{{
                    keg.support_kegiatan
                  }}</span>
                </tr>
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.stock_val") }}</th>
              <td>:</td>
              <td>
                <tr v-for="keg in detailKegiatan.salesKegiatan" :key="keg.id">
                  <span v-if="keg.stok !== null">{{ keg.stok }}</span>
                </tr>
              </td>
            </tr>
            <tr>
              <th>{{ $t("sp_act.label.pay") }}</th>
              <td>:</td>
              <td>
                <tr v-for="keg in detailKegiatan.salesKegiatan" :key="keg.id">
                  <span v-if="keg.pembayaran !== 'null'">{{
                    keg.pembayaran
                  }}</span>
                </tr>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>{{ $t("dailyreport.label.doc") }}</th>
              <td>:</td>
              <img
                v-if="detailKegiatan.dokumentasi"
                :src="url + detailKegiatan.dokumentasi"
                alt="dokumentasi"
              />
              <span v-else>
                {{ $t("global.no_doc") }}
              </span>
            </tr>
          </tbody>
        </table>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Card } from "@/components/index";
import JwtService from "@/core/services/jwt.service";
import { Q_LIST_KEGIATAN } from "@/graphql/tss";
// import { Q_LIST_REPORT } from "@/graphql/sa";
import { Q_LIST_REGION, Q_LIST_USERSEARCH } from "@/graphql/queries";
import { KegiatanSP, RoleType, filterDateType } from "@/graphql/enum.type";
import moment from "moment";
// import * as XLSX from "xlsx";
import Axios from "axios";

export default {
  components: {
    Card
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      dataLikuidasi: [],
      dataKegiatan: [],
      dataRegion: [],
      dataSP: [],

      url: JwtService.getURL(),
      filterInput: "",
      filterSP: [],
      filterRegion: [],
      filterKegiatan: null,
      searchSP: "",
      optionRole: [RoleType.SP],

      filterSelected1: "",
      filterSelected2: "",
      filterDateType: null,

      today: moment(new Date(new Date().setHours(0, 0, 0, 0))).format(
        "YYYY-MM-DD"
      ),
      yesterday: moment(
        new Date(new Date().setHours(0, 0, 0, 0) - 86400000)
      ).format("YYYY-MM-DD"),
      firstDayWeek: moment()
        .startOf("week")
        .toDate(),
      lastDayWeek: moment()
        .endOf("week")
        .toDate(),
      firstDayMonth: null,
      lastDayMonth: null,

      kegiatanSP: [
        KegiatanSP.DEMOPLOT,
        KegiatanSP.SPRAY,
        KegiatanSP.FFD,
        KegiatanSP.ODP,
        KegiatanSP.SFM,
        KegiatanSP.BFM,
        KegiatanSP.KFM,
        KegiatanSP.SCP,
        KegiatanSP.SCK
      ],

      tabLikuidasi: {
        currPage: 1,
        perPage: 10,
        totalPages: null,
        totalData: null,
        filterInput: "",
        filterSP: [],
        filterKegiatan: null,
        searchSP: "",
        customFilter: false,
        filterSelected1: null,
        filterSelected2: null
      },

      tabKegiatan: {
        currPage: 1,
        perPage: 10,
        totalPages: null,
        totalData: null,
        filterInput: "",
        customFilter: false,
        filterSelected1: null,
        filterSelected2: null,
        sp: [],
        tss: [],
        kegiatan: null
      },
      detailKegiatan: null,
      detailUser: JwtService.getDetail(),
      countryID: [],
      regionID: [],
      provID: [],
      kabID: [],
      roleTypeOptions: [RoleType.SP, RoleType.TSS],
      filterDateTypes: [
        filterDateType.TODAY,
        filterDateType.YESTERDAY,
        filterDateType.THIS_WEEK,
        filterDateType.THIS_MONTH,
        filterDateType.CUSTOM_DATE
      ],

      modalTitle: null,
      isLikuidasi: true
    };
  },
  apollo: {
    listLaporanKegiatan: {
      query: Q_LIST_KEGIATAN,
      variables() {
        return {
          page: this.tabKegiatan.currPage,
          limit: this.tabKegiatan.perPage,
          keyword: this.tabKegiatan.filterInput,
          sp_id: this.filterSP,
          tss_id: this.tabKegiatan.tss,
          region_id:
            this.filterRegion.length === 0
              ? this.getRegionID()
              : this.filterRegion,
          start_date: this.filterSelected1,
          end_date: this.filterSelected2,
          kegiatan: this.filterKegiatan,
          dateType: this.filterDateType
        };
      },
      debounce: 500,
      result({ data }) {
        this.dataKegiatan = data.listLaporanKegiatan.lapkegs;
        this.tabKegiatan.totalPages = data.listLaporanKegiatan.totalPage;
        this.tabKegiatan.totalData = data.listLaporanKegiatan.total;
      },
      error(err) {
        this.catchError(err);
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      }
    },
    listUser: {
      query: () => Q_LIST_USERSEARCH,
      variables() {
        return {
          keyword: this.searchSP,
          role: this.optionRole[0],
          country_id: this.getCountryID(),
          region_id: this.getRegionID(),
          prov_id: [],
          kab_id: [],
          kec_id: [],
          kel_id: [],
          tss_id: [],
          orderBy: "NAME",
          sortBy: "ASC"
        };
      },
      result({ data }) {
        this.dataSP = data.listUser.users;
      },
      error(e) {
        this.catchError(e);
      }
    },
    listRegion: {
      query: () => Q_LIST_REGION,
      result({ data }) {
        const r = this.getRegionID();
        if (r.length > 0) {
          this.dataRegion = data.listRegion.regions.filter(reg => {
            if (r.includes(parseInt(reg.id))) {
              return reg;
            }
          });
        } else {
          this.dataRegion = data.listRegion.regions;
        }
      }
    }
  },
  methods: {
    convertKegiatan(data) {
      let Value;
      switch (data) {
        case "DEMOPLOT":
          Value = "Demo Plot";
          break;
        case "FFD":
          Value = "FFD";
          break;
        case "SFM":
          Value = "Small Farmer Meeting";
          break;
        case "SPRAY":
          Value = "Spray Massal";
          break;
        case "ODP":
          Value = "ODP";
          break;
        case "BFM":
          Value = "Big Farmer Meeting";
          break;
        case "KFM":
          Value = "Key Farmer Meeting";
          break;
        case "SCP":
          Value = "Sales Call Petani";
          break;
        case "SCK":
          Value = "Sales Call Kios";
          break;
        default:
          Value = "Wrong Activity";
          break;
      }
      return Value;
    },
    getDay(value) {
      return moment(value).format("DD");
    },
    setFilterSelectedWeek() {
      this.filterSelected2 = this.lastDayWeek;
    },
    setFilterSelectedMonth() {
      this.filterSelected2 = this.lastDayMonth;
    },
    resetFilter() {
      this.filterSP = [];
      this.filterKegiatan = null;
      this.filterSelected1 = "";
      this.filterSelected2 = "";
      this.filterDateType = null;
      this.filterRegion = [];
    },
    resetFilter1() {
      this.filterSelected2 = "";
      this.customFilter = false;
      this.setPage();
    },

    setPage() {
      this.tabKegiatan.currPage = 1;
      this.tabLikuidasi.currPage = 1;
    },
    addPage() {
      if (this.tabLikuidasi.currPage < this.tabLikuidasi.totalPages) {
        this.tabLikuidasi.currPage++;
      }
      if (this.tabKegiatan.currPage < this.tabKegiatan.totalPages) {
        this.tabKegiatan.currPage++;
      }
    },
    redcPage() {
      if (this.tabLikuidasi.currPage > 1) {
        this.tabLikuidasi.currPage--;
      }
      if (this.tabKegiatan.currPage > 1) {
        this.tabKegiatan.currPage--;
      }
    },
    onKegDetail(data) {
      this.modalTitle = this.$t("sp_act.detail");
      this.detailKegiatan = data;
      // console.log(data);
      // console.log(this.hasilCallPetani);
      this.isLikuidasi = false;
      this.isDisabled = true;
      this.$bvModal.show("modal-detail");
    },
    resetForm() {
      this.detailLikuidasi = {
        tanggal: "",
        sp: "",
        produk: "",
        harga: "",
        qty: "",
        value: "",
        r2: "",
        r1: "",
        invoice: ""
      };
    },
    async handleExport() {
      this.isLoading = true;
      await Axios.post(
        process.env.VUE_APP_URL_GRAPHQL + "/laporan-kegiatan/excel",
        {
          dateType: this.handleCustomDateExport(this.filterDateType),
          start_date: this.filterSelected1,
          end_date: this.filterSelected2,
          user: this.filterSP,
          region: this.filterRegion,
          kegiatan: this.convertKegSP(this.filterKegiatan)
        },
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + JwtService.getToken(),
            "Content-Type": "application/json"
          }
        }
      )
        .then(response => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `kegiatansp-${this.dateFr(
            new Date(),
            "DDMMYYYYHHMMss"
          )}.xlsx`;
          link.click();
        })
        .catch(e => {
          alert(e);
        });
      this.isLoading = false;
    }
  },
  mounted() {
    const dateNow = new Date(new Date().setHours(0, 0, 0, 0));
    this.firstDayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
    this.lastDayMonth = new Date(
      dateNow.getFullYear(),
      dateNow.getMonth() + 1,
      0
    );
  }
};
</script>

<style>
.sp-report-tabs.active {
  color: #000 !important;
  background-color: #f3f3f3 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 2px solid #fd8833 !important;
}

.sp-report-tabs {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #273b4a;
}

.tab-custom div .nav-tabs {
  background-color: #f3f3f3;
  border-radius: 8px;
}

/* .nav.nav-tabs {
  background-color: #f3f3f3;
  border-radius: 8px;
} */
</style>
